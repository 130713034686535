<template>
  <div class="app-container">
    <div class="loginer">
      <img src="http://api.tengbocn.com/upload/image/loginbg.jpg" alt="" class="loginbg">
      <div class="loginmain">
        <a-row type="flex">
          <a-col :flex="2"><img src="http://api.tengbocn.com/upload/image/loginimg.jpg" alt=""></a-col>
          <a-col :flex="3">
            <div class="text-4xl logintit">
              LIMS登录后台
            </div>
            <div class="loginer1">
              <input ref="userNameInput" v-model="account" placeholder="请输入用户名" size="large"
                     class="w200 ant-input1"/>
              <!--                <a-icon slot="prefix" type="user"/>--><br/>
              <input ref="userNameInput" @keyup.enter="signInDoClick()" v-model="password" type="password"
                     placeholder="请输入密码" size="large" class="w200 ant-input1"/>
              <!--                <a-icon slot="prefix" type="key"/>--><br/>
              <br/>
              <button class="w200 btnlogin" @click="signInDoClick()">
                登录
              </button>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      type: false,
      container: false,
      account: '',
      password: '',

    }
  },
  methods: {
    signInDoClick() {
      this.$sa0.post({
        url: this.$api('Login'),
        data: {
          account: this.account,
          password: this.password,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.$member.setToken(response.data.token)
            this.$sa0.localStorage.set('USER_account', this.account);
            this.$router.push('/')
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    signUpBtnClick() {
      return layer.msg('暂未开放注册')
      this.type = true
    },
    signInBtnClick() {
      this.type = false
    },
    checkAutoLogin() {
      if ('account' in this.$route.query) {
        this.$sa0.post({
          url: this.$api('Login'),
          data: {
            account: this.$route.query.account,
            password: this.$route.query.password,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.$member.setToken(response.data.token)
              this.$sa0.localStorage.set('USER_account', this.account);
              this.$router.push('/')
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      }
    },
  },
  mounted() {
    this.checkAutoLogin()
  },
}
</script>

<style>
/*.ant-input{ height: 40px !important; line-height: 40px !important}*/
/*.ant-btn {*/
/*  line-height: 40px !important;*/
/*  height: 40px !important;*/
/*  font-size: 12px !important;*/
/*  }*/
</style>
<style scoped>
.loginbg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0
}

.loginmain {
  width: 1200px;
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginer {
  width: 100%;
}

.loginer1 {
  margin-top: 50px
}

.w200 {
  width: 300px;
  margin-bottom: 20px
}

.logintit {
  margin-top: 50px;
  font-weight: bold
}

.ant-input1 {
  width: 300px;
  height: 40px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box
}

.btnlogin {
  width: 300px;
  height: 40px;
  background: #1890ff;
  color: #ffffff;
  border-radius: 4px
}


</style>
